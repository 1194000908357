import "./css/App.css";
import "./css/normalize.css";
import "./css/webflow.css";
import "./css/active-immunity.webflow.css";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

import CookieConsent from "react-cookie-consent";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";

//Pages
import MainPage from "./pages/Main";
import Test from "./pages/Test";
import NotFoundPage from "./pages/404";
import Result from "./pages/Result";


function App() {

  const { t } = useTranslation();
  useEffect(() => { document.title = t('Мой чеклист – индивидуальный план медицинских исследований за 5 минут') });

  return (
    <Router>
         
      <body>
        <Switch>
          <Route exact path="/en" component={() => (<MainPage lng="en-US" />)}/>
          <Route exact path="/ru" component={() => (<MainPage lng="ru-RU" />)}/>
          <Route exact path="/" component={MainPage} />
          <Route exact path="/test" component={Test} />
          <Route path="/result" component={Result} />
          <Route exact path="/404" component={NotFoundPage} />
          <Redirect to="/" />
        </Switch>

        {/* <CookieConsent
         buttonText="Хорошо"
         style={{ background: "white", height: "auto", alignItems: "normal" }}
         buttonStyle={{ color: "#4e503b", marginRight: "0px", height: "30px", fontSize: "13px", alignItems: "normal" }}
         contentStyle={{padding: "0px", color: "black", fontSize: "12px", margin: "5px"}}
         expires={150}>Мы используем файлы-куки, чтобы вы могли заходить в свой чеклист без кода.</CookieConsent> */}
      </body>
    </Router>
    
  );
}

export default App;
