import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion"
import { Link } from "react-router-dom";
import Modal from 'react-modal';

import i18n from 'i18next';

import redDot from "../images/red-dot.svg"
import greenDot from "../images/green-dot.svg"
import yellowDot from "../images/yellow-dot.svg"

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.75)'
      },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const blurred = {
      filter: 'blur(7px)',
  }




const Card = (props) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    let testdata = {}

    var importanceHigh = ['High importance', "Существуют весомые доказательства необходимости исследования", redDot]
    var importanceMed = ["Moderate importance", "Существуют весомые доказательства необходимости исследования", yellowDot]
    var importanceLow = ["Low importance", "Существуют весомые доказательства необходимости исследования", greenDot]


    if (i18n.language.includes("ru")) {
        importanceHigh = ['Высокая важность', "Существуют весомые доказательства необходимости исследования", redDot]
        importanceMed = ["Средняя", "Существуют весомые доказательства необходимости исследования", yellowDot]
        importanceLow = ["Низкая важность", "Существуют весомые доказательства необходимости исследования", greenDot]
    
   }

  let demoData = () => {
      
      const demoTestNames = ["This is a demo test", "Demo test", "Unlock to get full checklist"]
      const demoCategory = ["Blood", "General Examination", "Vaccine"]
      const demoImportance = ["importanceLow", "importanceMed", "importanceHigh"]

      const randomNumber = Math.floor(Math.random() * 3);

      return testdata = {testName: demoTestNames[randomNumber],
                         category: demoCategory[randomNumber],
                         importance: demoImportance[randomNumber]}

  }

    const dotStyle = {
        paddingBottom: "3px",
    }


     props.testAnchors.forEach(currentItem => {
        if (currentItem.testAnchor == props.test) {
            testdata = currentItem;     
        }
        else {
            return;
        }
    });

    if (props.test == "Demo") {
        demoData()
    }

    let importanceData = [];
    switch (testdata.importance) {
        case "importanceHigh":
            importanceData = importanceHigh
            break;
        case "importanceMed":
            importanceData = importanceMed
            break;    
        case "importanceLow":
            importanceData = importanceLow
            break;      
    
        default:
            break;
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
    
         <div style={
              props.test == "Demo" ? blurred : null} onClick={openModal} className="test-block">
                <div className="test-text">{testdata.testName}</div>

                <div className="details">  
                    <div className="detail-container">
                        <div className="test-detail">{testdata.category}</div>
                    </div>
                    <div className="detail-container">
                        <div className="test-detail"><img width="16px" style={dotStyle} src={importanceData[2]} alt="Важность"/> {importanceData[0]}</div>
                    </div>
                    
                </div> 

            {/* <div class="text-block-2">{testdata.description}</div> */}
           
            {/* <h5 class="heading-6">Это бесплатно 😊</h5>
            <div class="text-block-2">Это исследование входит в ОМС. Приходите в свою поликлинику и попросите провести исследование. Это бесплатно</div> */}

            {/* <Modal style={customStyles} isOpen={modalIsOpen} onRequestClose={closeModal}>
            <h1>I'm a modal</h1>
            <button onClick={closeModal}>close</button>
        </Modal>   */}
        </div>
             
     )
}

export default Card;



