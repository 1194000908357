import React, { Component } from "react";
import { Link } from 'react-router-dom';
import logoRu from "../images/mychecklist-logo-p-500.png"
import logoEn from "../images/mychecklist-logo-p-500-en.png"
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';


//Survey
import Survey from "../components/survey/survey.jsx"
import "../css/survey.css"



const backButtonStyles = {
  textDecoration: "none",
  marginTop: "40px",
}


const Test = () => {

  const { t } = useTranslation();

  var logo = logoEn;



if (i18n.language.includes("ru")) {
  logo = logoRu;
}
  else {
    logo = logoEn;
  }

  return (
      <div className="home-container">  

        <div className="logo-container"><img src={logo} width="200px" loading="lazy" alt="" class="image-11"/></div>  
              
        <div className="survey-container">
            <Survey />
        </div>

        <Link to="/" style={backButtonStyles}>{t("← На главную")}</Link>
        </div>
    
  );
}

export default Test;