import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import {useHistory, Redirect} from 'react-router-dom';
import { HashLink as AnchorLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Popup, CloseButton } from '../components/popup';
import { Terms } from '../components/terms'


import logo from '../images/mychecklist-logo-p-500.png'
import NHS from '../images/220px-NHS_England_logo-1.png';
import Arrow from '../images/Arrow-2.svg';
import CancerNet from '../images/ASCO-CancerNet-Tag-RGB2020-1.png';
import AttentionIcon from '../images/attention-icon.svg';
import CWC from '../images/cwc-en-logo-1.png';
import Diacare500 from '../images/diacare_jnl_main_logo_0-1-p-500.png';
import Diacare from '../images/diacare_jnl_main_logo_0-1.png';
import ACS from '../images/ACS.png';
import MyCheckListLogoRu from '../images/mychecklist-logo-2.png';
import MyCheckListLogoEn from '../images/mychecklist-logo-2-en.png';
import MyCheckListLogo500 from '../images/mychecklist-logo-p-500.png';
import MyCheckListPaperRu from '../images/mychecklist-paper.png';
import MyCheckListPaperEn from '../images/mychecklist-paper-en.png';
import MyCheckListPaper500 from '../images/mychecklist-paper-p-500.png';
import Print from '../images/print.svg';
import Privacy from '../images/privacy.svg';
import UTD from '../images/utd-logo-desktop-1.png';

import promoVideoMp4Ru from '../images/packshot-small2-transcode.mp4';
import promoVideoMp4En from '../images/promovideo-en-compressed.mp4';
import promoVideoWebM from '../images/packshot-small2-transcode.webm';
import videoBg from '../images/packshot-small2-poster-00001.jpg';

import tabletRu from '../images/tablet.png'
import tabletEn from '../images/tablet-en.png'
import doctorChecklist from '../images/doctor-checklist.svg';
import example1Ru from '../images/example1.png'
import example1En from '../images/example1-en.png'
import example2Ru from '../images/example2.svg'
import example2En from '../images/example2-en.svg'
import example3Ru from '../images/example3.svg'
import example3En from '../images/example3-en.svg'
import checkBox from '../images/checkbox.svg'
import priceTagRu from '../images/pricetag.svg'
import priceTagEn from '../images/pricetag-en.svg'
import securePayment from '../images/secure-payment.png'
import vaccinesRu from '../images/vaccines.svg'
import vaccinesEn from '../images/vaccines-en.svg'

import importanceGreenRu from '../images/importance-green.svg'
import importanceRedRu from '../images/importance-red.svg'
import importanceYellowRu from '../images/importance-yellow.svg'

import importanceGreenEn from '../images/importance-green-en.svg'
import importanceRedEn from '../images/importance-red-en.svg'
import importanceYellowEn from '../images/importance-yellow-en.svg'

import applePay from '../images/ApplePay-1.svg';
import visa from '../images/Visa-1.svg';
import masterCard from '../images/Mastercard-1.svg';
import mir from '../images/Mir-1.svg'
import googlePay from '../images/Gpay-1.svg'




function MainPage(props) {
  
  const [pin, setPin] = useState("");
  const [isPopupOpened, setIsOpen] = useState(false);
  const [noPinMessage, setPinMessage] = useState("");
  const history = useHistory();

  const allCookies = document.cookie;
  var cookiePin = allCookies.split("=");
  const { t } = useTranslation();
  
   useEffect(() => {
    if (typeof props.lng !== 'undefined') {
    i18n.changeLanguage(props.lng)}
    }, []);
 

 

  // Cookies
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}


  var logo = MyCheckListLogoEn;
  var MyCheckListPaper = MyCheckListPaperEn;
  var tablet = tabletRu;
  var example1 = example1En;
  var example2 = example2En;
  var example3 = example3En;
  var priceTag = priceTagEn;
  var vaccines = vaccinesEn;
  var importanceGreen = importanceGreenEn;
  var importanceRed = importanceRedEn;
  var importanceYellow = importanceYellowEn;
  var promoVideoMp4 = promoVideoMp4En;
  var noPinMessageString = "We coudn't find this code"
  
  
  


 if (i18n.language.includes("ru")) {
      logo = MyCheckListLogoRu;
      MyCheckListPaper = MyCheckListPaperRu;
      tablet = tabletRu;
      example1 = example1Ru;
      example2 = example2Ru;
      example3 = example3Ru;
      priceTag = priceTagRu;
      vaccines = vaccinesRu;
      importanceGreen = importanceGreenRu;
      importanceRed = importanceRedRu;
      importanceYellow = importanceYellowRu;
      promoVideoMp4 = promoVideoMp4Ru;
      noPinMessageString = "Мы не смогли найти такой пин"
 }
      else {
        logo = MyCheckListLogoEn;
        MyCheckListPaper = MyCheckListPaperEn;
        tablet = tabletEn;
        example1 = example1En;
        example2 = example2En;
        example3 = example3En;
        priceTag = priceTagEn;
        vaccines = vaccinesEn;
        importanceGreen = importanceGreenEn;
        importanceRed = importanceRedEn;
        importanceYellow = importanceYellowEn;
        promoVideoMp4 = promoVideoMp4En;
      }


  const changeLanguage = () => {
    if (i18n.language.includes("en")) {
      i18n.changeLanguage("ru-RU");
    }
    else {i18n.changeLanguage("en-US")}
    
  }

  const handleSubmit = (event) => {

    if (pin.length !== 0) {

    axios.get(`https://checklistbackend-serovg.b4a.run//users/${pin}`)
    .then(userdata => {console.log(userdata);
      if (Object.keys(userdata.data).length !== 0)
        {history.push(
          {pathname: '/result',
           state: {pin}
      })}
       else {console.log('no such pin'); setPinMessage(noPinMessageString)}
      })
    .catch(err => console.log(err))  
    event.preventDefault();
  }
  }

  const applyForTestStyling = {
    WebkitTransform: 'translate3d(0, 169px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MozTransform: 'translate3d(0, 169px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    MsTransform: 'translate3d(0, 169px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
    transform: 'translate3d(0, 169px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
  }

  const videoStyle = {
    backgroundImage: 'url(' + videoBg + ')',
  };

console.log(typeof props.location.state);

if (typeof props.location.state == "undefined" ) {
    if (getCookie('pin') !== undefined ) {
      return <Redirect to="/result" /> 
    }
}
else {
  
  if (getCookie('pin') !== undefined && props.location.state.prevPath !== "/result") {
  return <Redirect to="/result" />  
  }
}



return (
  <div className="body">
  <div className="columns">
    <div id="w-node-_419b074c-55c0-cbc5-fed0-fdf7a69a6f6c-46f5b903" className="sidebar">
      <div className="fixeddiv">
        <div><img src={logo} loading="lazy" width="165" alt="" className="logo"/></div>
        <Link to="/test" className="cta-button">
          <div className="div-block-5">
            <div className="div-block-6"><img src={MyCheckListPaper} loading="lazy" width="187" sizes="(max-width: 479px) 79vw, (max-width: 767px) 34vw, (max-width: 991px) 187px, 12vw" scrSet={'${MyCheckListPaper500} 500w, ${MyCheckListPaper} 583w'} alt=""/></div><img src={Arrow} loading="lazy" alt="" className="image-2" />
          </div>
          <div className="cta-header">{t("Пройти тест и получить свой чеклист")}</div>
        </Link>
        <div className="login">
          <div className="w-form">
            <form onSubmit={handleSubmit} id="email-form" name="email-form" className="w-clearfix" data-name="Email Form"><label for="name" className="field-label-2"><p><strong>{t("Уже проходили тест?")}</strong></p> <p>{t("Введите пин-код:")}</p></label><input value={pin} onChange={e => setPin(e.target.value)} type="text" className="text-field-2 w-input" maxlength="256" name="name" data-name="Name" placeholder="" id="name"/><input type="submit" value={t('Открыть')} data-wait="Please wait..." className="submit-button-2 w-button"/></form><div className="noPin">{noPinMessage}</div>
          </div>
        </div>        
        {/* <div className="disclaimer">Наш отчет ориентирован на здоровых людей. Всегда следуйте рекомендациям врача.</div> */}
        
        <a href="#" className="support-link">support@mychecklist.ru</a>

        <div className="smalltext"> {t("18+ Мой чеклист — информационный сервис и не предоставляет медицинских услуг.")} <p></p>{t("Для постановки диагноза и назначения плана лечения следует записаться на очный прием к специалисту.")}</div>
      </div>
    </div>
    <div className="content">
      <h1 className="heading-landing">{t("Получите личный план обследований за 5 минут")}</h1>
      <div class="video-container">
        <div data-poster-url={videoBg} data-video-urls={promoVideoMp4} dataAutoPlay="true" dataLoop="true" dataWfIgnore="true" class="background-video w-background-video w-background-video-atom"><video autoPlay="muted" loop="1" style={videoStyle} muted="1" playsinline="1" data-wf-ignore="true" data-object-fit="cover">
            <source src={promoVideoMp4}/>
            <source src={promoVideoWebM}/>
          </video></div>
      </div>
      <div className="leadgrid">
        <div className="big-lead">{t("Вы проходите опрос, где отвечаете на вопросы о своем образе жизни, хронических и семейных болезнях.")} <br/></div>
        <div className="small-text gray">{t("В анкете вы отвечаете на 7 простых вопросов о своем здоровье. Это занимает не более 5 минут.")}<br/></div>
        <div className="big-lead">{t("На основе ваших ответов, мы готовим вам индивидуальный план медицинских обследований.")}</div>
        <div className="small-text gray">{t("Система проанализует ваши ответы и подготовит для вас ваш персональный чеклист с обследованиями, которые рекомендуется пройти именно вам.")}</div>
        <div className="big-lead">{t("Следуйте рекомендациям в отчете, чтобы не пропустить важное обследование положенное вам в вашем возрасте.")}</div>
        <div className="small-text gray">{t("Распечатайте свой чеклист и обращайтесь к нему каждый год, чтобы посмотреть какие исследования у вас по плану в этом году")}</div>
      </div>
      <div className="navigation">
        <AnchorLink to="#values" className="navlink w-inline-block">
          <div className="navtext">{t("Чем полезен наш отчет")}</div>
        </AnchorLink>
        <AnchorLink to="#howTo" className="navlink w-inline-block">
          <div className="navtext">{t("Как работает система расчета")}</div>
        </AnchorLink>
        <AnchorLink to="#whatIf" className="navlink w-inline-block">
          <div className="navtext">{t("Что если я пропущу начало опасного заболевания?")}</div>
        </AnchorLink>
        <AnchorLink to="#data" className="navlink w-inline-block">
          <div className="navtext">{t("Откуда мы берем данные")}</div>
        </AnchorLink>
        <AnchorLink to="#doctor" className="navlink w-inline-block">
          <div className="navtext">{t("Ваш врач и чеклист: как быть?")}</div>
        </AnchorLink>
      </div>
      <div data-w-id="81e2e25a-d9c8-64ff-e04d-5dae0b02c885" className="featuresblock">
        <div id="w-node-_2e904312-55a0-8eef-c587-80e1fe302c85-46f5b903" className="div-block-12"><img src={tablet} loading="lazy" width="562" sizes="(max-width: 479px) 155.25px, (max-width: 767px) 32vw, (max-width: 991px) 29vw, 18vw" alt=""/></div>
        <div id="w-node-_19dc4d12-d9ef-d903-5b1b-425b43c8f517-46f5b903" className="featurecontainer">
          <div className="emoji">💉</div>
          <div className="div-block-11">
            <div className="featureheader">{t("Прививки")}</div>
            <div className="text-block-6">{t("Привики позволяют забыть о существовании многих тяжелых болезней и практически не имеют побочных действий.")}</div>
          </div>
        </div>
        <div id="w-node-_4a30e0a5-0853-4fcc-42e8-b37fbf820cf4-46f5b903" className="featurecontainer">
          <div className="emoji">🦀</div>
          <div className="div-block-11">
            <div className="featureheader">{t("Скрининги на онкологию")}</div>
            <div className="text-block-6">{t("Существует множество различных исследований на онкологию. Но их нужно делать, когда к ним есть прямые показания.")}</div>
          </div>
        </div>
        <div id="w-node-_3bdeb84f-a87d-4eda-6710-227786752584-46f5b903" className="featurecontainer">
          <div className="emoji">🩸</div>
          <div className="div-block-11">
            <div className="featureheader">{t("Исследования крови")}</div>
            <div className="text-block-6">{t("Исследования крови позволяют быстро выявить проблемы с сахаров или холестерином.")}</div>
          </div>
        </div>
        <div id="w-node-aa99d17f-8d1c-6249-0c58-eaf69c9f946c-46f5b903" className="featurecontainer">
          <div className="emoji">⚡</div>
          <div className="div-block-11">
            <div className="featureheader">{t("Лучевая диагностика")}</div>
            <div className="text-block-6">{t("КТ или МРТ помогают выявлять некоторые виды онкологии. Однако не стоит их делать слишком часто – КТ не безопасно, а МРТ дорого.")}</div>
          </div>
        </div>
        <div id="w-node-_1624e3c9-fcc5-6312-fbb2-f95190db6157-46f5b903" className="featurecontainer">
          <div className="emoji">👩‍⚕️</div>
          <div className="div-block-11">
            <div className="featureheader">{t("Профилактические осмотры докторов")}</div>
            <div className="text-block-6">{t("Осмотр специалиста – важнейшая часть диагностики некоторых заболеваний, которую мы включаем в чеклист.")}</div>
          </div>
        </div>
      </div>
      <div className="landing-div">
        <div id="values" className="heading bold">{t("Чем полезен наш отчет?")}</div>
        <div className="landing-body-text">{t("Ранняя диагностика помогает предотвратить развитие серьёзных заболеваний. Но ежегодное обследование всего организма стоит дорого, занимает много времени, доставляет неудобства, и, чаще всего, не приносит желаемого результата, потому что доктору нужно знать, на что обращать внимание при поиске.")}<br/><br/>{t("Поэтому частые полные исследования всего организма приносят больше вреда, чем пользы. Кроме того, излишняя диагностика серьезно увеличивает шансы ложных находок. Это может привести к депрессии и развитию уже реальных болезней и долгому лечению болезни, которой либо нет, либо можно не лечить.")}<br/><br/>{t("Более того, ни одно исследования нельзя назвать 100% безопасным. Поэтому мы, на основе современных международных и отечественных рекомендаций выбрали те исследования, которые признаются полезными большинством докторов развитых стран. Мы включаем в чеклист только те исследования, которые доказали свою эффективность для увеличения продолжительности и качества жизни.")}</div>
      </div>
      <div className="salepromo"><img src={MyCheckListPaper} loading="lazy" width="568" sizes="(max-width: 767px) 100vw, 568px" alt=""/>
        <div className="div-block-13"><img src={vaccines} loading="lazy" alt="" className="image-12"/></div>
        <Link to="/test" className="pricetag w-inline-block"></Link>

      </div>
    
      <div className="attention-block">
        <div className="big-lead">{t("Ежегодные обследования всего организма скорее всего не помогут вам прожить дольше.")}<br/></div>
        <div className="landing-body-text">{t("Было множество исследований о эффективности ежегодных проверок. Результаты показали, что в целом, они не помогут вам стать здоровее или жить дольше.")}<br/></div>
      </div>
      <div id="whatIf" className="questionblock">
        <div  className="heading bold">{t("– А что, если я пропущу начало опасного заболевания?")}<br/></div>
        <p className="landing-body-text">{t("К сожалению, такой риск есть всегда. Однако даже ежегодные дорогостящие полные исследования не могут исключать такие случаи: опасная онкология может развиться и за несколько месяецев.")} <br/><br/>{t("При этом многие исследования небезопасны и доставляют дискомфорт. Врачи, боясь пропустить что-то серьезное, часто обращают внимания на неопасные детали, назначают дорогое и опасное лечение: диагностические операции, препараты с побочными эффектами.")}<br/><br/>{t("Но никогда не лишним будет проконсультироваться с доктором и рассказать о своих опасениях.")}</p>
      </div>
      <div id="data" className="landing-div grid">
        <div id="w-node-_90ad4467-b300-a176-bb58-fe5f60b18a3b-46f5b903">
          <div className="heading bold">{t("Как мы определяем какие исследования вам показаны?")}</div>
          <div>{t("Мы изучили самые передовые рекомендации ведущих медицинских ассоциаций разных стран, сопоставили и адаптировали их.")}<br/><br/>{t("Преобретая чеклист вы получаете сконцентрированный мировой западный опыт в сфере ранней диагностики большого количества недугов.")}</div>
        </div>
        <div id="w-node-abd47a99-2c90-3158-d9c2-01bf233e5ebe-46f5b903" className="clinic-logo"><img src={ACS} loading="lazy" width="143" alt="" className="clinic-logo"/>
          <div className="small-text">{t("Американское общество онкологии – это национальная, общественная организация, созданное для уменьшения количества онкологических заболеваний.")}</div>
        </div>
        <div id="w-node-_3aafce8c-2938-93b4-a006-f33b1ff95c09-46f5b903" className="clinic-logo"><img src={NHS} loading="lazy" width="116" alt="" className="clinic-logo"/>
          <div className="small-text">{t("Исполнительный комитет департамента здравоохранения Великобритании")}<br/></div>
        </div>
        <div id="w-node-ac0388b1-83c8-b129-68e9-b446c297bfca-46f5b903" className="clinic-logo"><img src={CWC} loading="lazy" width="157" alt="" className="clinic-logo"/>
          <div className="small-text">{t("Разумный выбор Канада – национальная программа для уменьшения количество излишнего тестирования и лечения в здравоохранении.")}</div>
        </div>
        <div id="w-node-ef85c3d9-759e-8fe7-7aad-5a0ac407b0dc-46f5b903" className="clinic-logo"><img src={CancerNet} loading="lazy" width="241" alt="" className="clinic-logo"/>
          <div className="small-text">{t("Портал об онкологии с информацией для пациентов.")}</div>
        </div>
        <div id="w-node-_92563a48-fd75-6267-e63b-b4e8d716528d-46f5b903" className="clinic-logo"><img src={Diacare} loading="lazy" width="298" sizes="(max-width: 479px) 298px, (max-width: 991px) 30vw, 18vw"  alt="" className="clinic-logo"/>
          <div className="small-text">{t("Лечение Диабета – это журнал для практикующих врачей, чья цель узнавать больше, стимулировать исследования и предоставлять лучшее лечение для людей с диабетом")}</div>
        </div>
        <div id="w-node-_36cdd141-58e6-9378-6d0d-ad0d755437ab-46f5b903" className="clinic-logo"><img src={UTD} loading="lazy" width="168" alt="" className="clinic-logo"/>
          <div className="small-text">{t("UpToDate обеспечивает клинические достоверные данные, для принятия медицинских решений.")}</div>
        </div>
      </div>
      <div id="doctor" className="landing-div">
        <div className="heading bold">{t("Покажите чеклист своему терапевту")} </div>
        <div className="landing-body-text">{t("Ваш доктор знает вас лучше, поэтому, возможно, что-то добавит в список и проконсультирует вас по деталям исследования. Чеклист не заменяет консультацию доктора, а лучше всего работает совместно с ней.")}<br/></div><img src={doctorChecklist} loading="lazy" alt="" className="image-6"/>
      </div>
      <div className="landing-div additionaltopmargin">
        <div id="howTo" className="heading bold">{t("Как работает наша система расчета")}</div>
        <div className="div-block-16">
          <div>{t("Допустим, вы указали свой возраст – 45 лет.")}</div>
          <div>{t("И рост и вес – 165 и 85. Система вычисляет ваш индекс массы тела – 32. Это ожирение первой степени.")}</div>
        </div><img src={example1} loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 89vw, 58vw" alt="" className="image-7"/>
        <div className="landing-body-text">{t("Система вычислила на основании ваших данных, вам рекомендуется делать скрининг аневризмы брюшной аорты каждые 5 лет. Тогда вы увидите в своем чеклисте этот блок каждые пять лет.")}<br/></div><img src={example2} loading="lazy" alt="" className="image-8"/>
        <div className="landing-body-text">{t("А если вы еще и поставили галочку, что в семье были случаи сердечно-сосудистых катастроф:")}<br/></div><img src={example3} loading="lazy" alt="" className="image-9"/>
        <div className="landing-body-text">{t("Скрининг аневризмы брюшной аорты рекомендуется делать чаще – один раз каждые 2 года и каждый год после 50 лет. Это все рассчитывается нашей системой и отображается в вашем чеклисте. ")}<br/><br/>{t("Вам не нужно высчитывать графики десятка обследований – сервис все рассчитает для вас.")}<br/></div>
      </div>
      <div className="landing-div additionaltopmargin">
        <div className="heading bold">{t("Не все исследования одинаково важны.")}</div>
        <div className="landing-body-text">{t("Некоторые исследования очень важны и их нельзя пропустить. Другие делать не обязательно, но желательно. Какие-то еще можно сделать по вашему желанию.")} <br/><br/>{t("Эта информация отображается в карточке исследования")}<br/></div>
        <div className="importanceblock">
          <div className="importancecontainer"><img src={importanceGreen} loading="lazy" alt="" className="image-10"/>
            <div>{t("Такие исследования делать не обязательно, но если вы хотите, можете пройти их на всякий случай.")}</div>
          </div>
          <div className="importancecontainer"><img src={importanceYellow} loading="lazy" alt="" className="image-10"/>
            <div>{t("Не стоит пропускать важные исследования. Они помогут выявить заболевания на ранней стадии.")}</div>
          </div>
          <div className="importancecontainer"><img src={importanceRed} loading="lazy" alt="" className="image-10"/>
            <div>{t("Обычно это обследования на онкологию, которые увеличат шансы на ранней стадии выявить смертельно-опасные заболетвания")}</div>
          </div>
        </div>
      </div>
      <div className="landing-div additionaltopmargin">
        <div className="heading bold">{t("Мы рекомендуем исследования, которые нужно проходить здоровым людям. Не основывайте свой план обследований на наших рекомендациях в следующих случаях:")}</div>
        <div className="desclamerblock">
          <div className="importancecontainer"><img src={checkBox} loading="lazy" alt="" className="image-11"/>
            <div>{t("Вы знаете о заболевании, которое может повлиять на периодичность исследований")}</div>
          </div>
          <div className="importancecontainer"><img src={checkBox} loading="lazy" alt="" className="image-11"/>
            <div>{t("Ваш доктор назначил вам иные или в иной периодичности исследования – всегда слушайте своего доктора, это безусловный приоритет.")}</div>
          </div>
          <div className="importancecontainer"><img src={checkBox} loading="lazy" alt="" className="image-11"/>
            <div>{t("Вы чувствуете, что надо пройти исследование. Прислушивайтесь к своему организму и если вы ощущаете потребность пройти исследование – обратитесь к врачу")}</div>
          </div>
        </div>
      </div>
      <div className="privacy">
        <div id="w-node-_16e16ae0-ec29-5e17-f646-8896ffe44a66-46f5b903"><img onClick={() => changeLanguage()} src={Privacy} loading="lazy" alt=""/></div>
        <div>
          <div className="landing-body-text white">{t("Отчет обезличен, мы не ничего не узнаем про вас. После прохождения опроса, система выдаст вам случайный цифровой код, который вы сможете использовать, чтобы вернуться к своему чеклисту позднее.")}</div>

  
        </div>
      </div>
      <p className="smalltext">{t("Создавая отчет, вы принимаете")} <span onClick={() => setIsOpen(true)}><u>{t("Пользовательское соглашение")}</u></span>.  {t("Разработано Георгием Серовым и Баширом Арсланалиевым в 2021 году. ИП Серов Георгий Юрьевич. ИНН: 057001350669, ОГРНИП 316057100114072.")}</p>
    </div>
  </div>
 
  <Link to="/test" className="cta-mobile w-inline-block">
    <div>{t("Пройти тест")}</div>
  </Link>

  
{isPopupOpened &&  <Popup>        
    <Terms/>
    <div onClick={() => setIsOpen(false)}><CloseButton/></div>
  </Popup>}

</div>

  )}

export default MainPage;