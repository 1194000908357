export const json = {
 locale: "ru-RU",
 logo: "Hello",
 logoPosition: "none",
 pages: [
  {
   name: "1.1 Gender",
   elements: [
    {
     type: "radiogroup",
     name: "gender",
     title: {
      default: "Select your gender",
      "ru-RU": "Укажите ваш пол"
     },
     titleLocation: "top",
     hideNumber: true,
     isRequired: true,
     choices: [
      {
       value: "man",
       text: {
        default: "Man",
        "ru-RU": "Мужской"
       }
      },
      {
       value: "woman",
       text: {
        "ru-RU": "Женский",
        default: "Woman"
       }
      }
     ],
     colCount: 2
    }
   ],
   questionTitleLocation: "hidden",
   title: {
    default: "Gender",
    "ru-RU": "Пол"
   }
  },
  {
   name: "1.2 Age, weight and height",
   elements: [
    {
     type: "panel",
     name: "panel2",
     elements: [
      {
       type: "text",
       name: "age",
       useDisplayValuesInTitle: false,
       minWidth: "150px",
       maxWidth: "100px",
       startWithNewLine: false,
       title: {
        "ru-RU": "Возраст",
        default: "Age"
       },
       hideNumber: true,
       validators: [
        {
         type: "numeric",
         minValue: 18,
         maxValue: 99
        },
        {
         type: "text"
        },
        {
         type: "numeric",
         minValue: 18,
         maxValue: 89
        }
       ],
       size: 3
      }
     ],
     width: "200px",
     minWidth: "200px",
     maxWidth: "200px"
    },
    {
     type: "panel",
     name: "panel1",
     elements: [
      {
       type: "radiogroup",
       name: "metric",
       minWidth: "600px",
       title: {
        default: "Weight and height",
        "ru-RU": "Вес и рост"
       },
       defaultValue: "metric",
       choices: [
        {
         value: "metric",
         text: {
          default: "Kilograms and cantimeters",
          "ru-RU": "Килограммы и сантиметры"
         }
        },
        {
         value: "imperial",
         text: {
          default: "Pounds and feet-inches",
          "ru-RU": "Фунты и футы+дюймы"
         }
        }
       ]
      },
      {
       type: "text",
       name: "weight",
       visibleIf: "{metric} = 'metric'",
       width: "150px",
       minWidth: "150px",
       maxWidth: "150px",
       startWithNewLine: false,
       title: {
        default: "Weight",
        "ru-RU": "Вес (кг\n)"
       },
       validators: [
        {
         type: "numeric",
         minValue: 40,
         maxValue: 180
        }
       ],
       inputType: "number",
       min: "40",
       max: "190",
       step: 1
      },
      {
       type: "text",
       name: "height",
       visibleIf: "{metric} = 'metric'",
       width: "150px",
       minWidth: "150px",
       maxWidth: "150px",
       startWithNewLine: false,
       title: {
        default: "Height",
        "ru-RU": "Рост (см)"
       },
       validators: [
        {
         type: "numeric",
         minValue: 140,
         maxValue: 215
        }
       ],
       inputType: "number",
       min: "140",
       max: "210"
      },
      {
       type: "text",
       name: "question1",
       visibleIf: "{metric} = 'imperial'",
       width: "160px",
       minWidth: "160px",
       maxWidth: "160px",
       startWithNewLine: false,
       title: {
        default: "Weight (pounds)",
        "ru-RU": "Вес  (фунты)"
       },
       validators: [
        {
         type: "numeric",
         maxValue: 180
        }
       ],
       inputType: "number",
       min: "40",
       max: "190",
       step: 1
      },
      {
       type: "text",
       name: "heightFeet",
       visibleIf: "{metric} = 'imperial'",
       width: "150px",
       minWidth: "150px",
       maxWidth: "150px",
       startWithNewLine: false,
       title: {
        default: "Height (feet)",
        "ru-RU": "Рост (футы)"
       },
       validators: [
        {
         type: "numeric",
         maxValue: 215
        }
       ],
       inputType: "number",
       min: "140",
       max: "210"
      },
      {
       type: "text",
       name: "heightInches",
       visibleIf: "{metric} = 'imperial'",
       width: "150px",
       minWidth: "150px",
       maxWidth: "150px",
       startWithNewLine: false,
       title: {
        default: "Height (inches)",
        "ru-RU": "Рост (дюймы)"
       },
       valueName: "heightInches",
       defaultValue: 0,
       validators: [
        {
         type: "numeric",
         maxValue: 215
        }
       ],
       inputType: "number",
       min: "0",
       max: "12"
      }
     ],
     startWithNewLine: false,
     maxWidth: "500px"
    }
   ],
   title: {
    default: "Age, weight and height",
    "ru-RU": "Возраст, вес и рост"
   }
  },
  {
   name: "1.3 Bad Habits",
   elements: [
    {
     type: "panel",
     name: "smoking",
     elements: [
      {
       type: "radiogroup",
       name: "smokingQuestion",
       titleLocation: "hidden",
       choices: [
        {
         value: "smokingNow",
         text: {
          "ru-RU": "Курю сейчас",
          default: "I smoke"
         }
        },
        {
         value: "quitSmoking",
         text: {
          "ru-RU": "Бросил",
          default: "I quit smoking"
         }
        },
        {
         value: "noSmoking",
         text: "I don't smoke"
        }
       ],
       colCount: 2
      }
     ],
     title: {
      default: "Smoking",
      "ru-RU": "Курение"
     }
    },
    {
     type: "panel",
     name: "alcohol",
     elements: [
      {
       type: "radiogroup",
       name: "alcoholDrink",
       titleLocation: "hidden",
       choices: [
        {
         value: "heavyDrinker",
         text: {
          default: "I drink alcohol more than 1 time a week",
          "ru-RU": "Пью алкоголь чаще раза в неделю"
         }
        },
        {
         value: "lightDrinker",
         text: {
          default: "I drink alcohol less than 1 time a week",
          "ru-RU": "Пью алкоголь реже одного раза в неделю"
         }
        },
        {
         value: "noDrinker",
         text: {
          "ru-RU": "Не пью",
          default: "I don't drink alcohol"
         }
        }
       ]
      }
     ],
     title: {
      default: "Alcohol",
      "ru-RU": "Алкоголь"
     }
    }
   ],
   title: {
    default: "Bad Habits",
    "ru-RU": "Вредные привычки"
   }
  },
  {
   name: "1.4 Blood and Sugar",
   elements: [
    {
     type: "panel",
     name: "Blood Pressure",
     elements: [
      {
       type: "radiogroup",
       name: "bloodPressure",
       titleLocation: "hidden",
       choices: [
        {
         value: "low",
         text: {
          "ru-RU": "Пониженное",
          default: "Low"
         }
        },
        {
         value: "normal",
         text: {
          "ru-RU": "Нормальное",
          default: "Normal"
         }
        },
        {
         value: "high",
         text: {
          "ru-RU": "Повышенное",
          default: "High"
         }
        }
       ],
       colCount: 3
      }
     ],
     title: {
      "ru-RU": "Ваше давление",
      default: "What's your blood pressure"
     },
     startWithNewLine: false,
     width: "100%"
    },
    {
     type: "panel",
     name: "panel3",
     elements: [
      {
       type: "checkbox",
       name: "high cholesterol",
       minWidth: "",
       maxWidth: "500",
       startWithNewLine: false,
       titleLocation: "hidden",
       description: {
        default: "Please, select if you know that your cholesterol level are elevated",
        "ru-RU": "Отметьте, если вы знаете, что ваш холестерин повышен. \nЕсли не знаете, мы пришлем приглашение на тест в течение года"
       },
       descriptionLocation: "underInput",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Мой холестерин повышен",
          default: "I have high cholesterol level"
         }
        }
       ],
       maxSelectedChoices: 1
      },
      {
       type: "checkbox",
       name: "diabetes",
       minWidth: "",
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          default: "I have diabetes",
          "ru-RU": "У меня диабет"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "brokeBone",
       minWidth: "",
       title: {
        "ru-RU": "У меня был перелом при легком инциденте",
        default: "I broke bone in a minor accident"
       },
       titleLocation: "hidden",
       description: {
        "ru-RU": "Например, если вы случайно ломали руку или иную часть тела в легком, на первый взгляд, несущественном прошествии.",
        default: "If you ever broke your bone in a minor accident"
       },
       descriptionLocation: "underInput",
       choices: [
        {
         value: "true",
         text: {
          default: "I broke bone in a minor accident",
          "ru-RU": "У меня был перелом при легком инциденте"
         }
        }
       ]
      }
     ],
     title: {
      "ru-RU": "Дополнительные вопросы",
      default: "Additional Questions"
     }
    }
   ],
   title: {
    "ru-RU": "Кровь и Сахар",
    default: "Blood and sugar"
   }
  },
  {
   name: "1.5 Family Strokes",
   elements: [
    {
     type: "panel",
     name: "panel6",
     elements: [
      {
       type: "checkbox",
       name: "Stroke",
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Инфаркт",
          default: "Heart Attack"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "insult",
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Инсульт",
          default: "Stroke"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "relativeHipBroke",
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Перелом шейки бедра",
          default: "Femoral neck fracture"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "familyDiabetis",
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Диабет",
          default: "Diabetis"
         }
        }
       ]
      }
     ],
     title: {
      "ru-RU": "Семейные недуги",
      default: "Family diseases"
     },
     description: {
      "ru-RU": "Отметьте болезни, которые случались с вашими близкими родственниками.",
      default: "Please choose diseases that happened with your close relatives"
     }
    }
   ]
  },
  {
   name: "1.6 Chronic Diseases",
   elements: [
    {
     type: "panel",
     name: "panel7",
     elements: [
      {
       type: "checkbox",
       name: "chronicLungs",
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Хроническая болезнь легких",
          default: "Chronic lung disease"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "chronicHeart",
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Хроническая болезнь сердечно-сосудистой системы",
          default: "Chronic cardiovascular disease"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "chronicLiver",
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Хроническая болезнь печени",
          default: "Chronic liver disease"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "chronicStomach",
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Хроническая болезнь желудка",
          default: "Chronic stomach disease"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "chronicKidneys",
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Хроническая болезнь почек",
          default: "Chronic kidney disease"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "Hiv",
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "У меня ВИЧ",
          default: "I'm HIV-positive"
         }
        }
       ]
      }
     ],
     title: {
      "ru-RU": " Хронические заболевания",
      default: "Chronic diseases"
     },
     description: {
      "ru-RU": "Отметьте свои хронические заболевания. Если ничего нет, пропустите эту страницу. Обратите внимание, что мы не включаем программу лечения хронических заболеваний в отчет. Эти данные используются для выявления дополнительных рисков.",
      default: "Select your chronic illnesses. If there are none, skip this page. Please note that we do not include a chronic disease management program in the report. This data is used to identify additional risks."
     }
    }
   ]
  },
  {
   name: "2.1 Cancer Men",
   elements: [
    {
     type: "panel",
     name: "panel4",
     elements: [
      {
       type: "checkbox",
       name: "prostateCancer",
       width: "200px",
       minWidth: "200px",
       maxWidth: "200px",
       startWithNewLine: false,
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          default: "Prostate cancer",
          "ru-RU": "Простаты"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "colonCancerMan",
       width: "200px",
       minWidth: "200px",
       maxWidth: "200px",
       startWithNewLine: false,
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          default: "Colon Cancer",
          "ru-RU": "Прямой кишки"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "stomachCancerMan",
       width: "200px",
       minWidth: "200px",
       maxWidth: "200px",
       startWithNewLine: false,
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Желудка",
          default: "Stomach"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "lungsCancerMan",
       width: "200px",
       minWidth: "200px",
       maxWidth: "200px",
       startWithNewLine: false,
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          default: "Lungs cancer",
          "ru-RU": "Легких"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "melanomaMan",
       width: "200px",
       minWidth: "200px",
       maxWidth: "200px",
       startWithNewLine: false,
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          default: "Melanoma",
          "ru-RU": "Меланома"
         }
        }
       ]
      }
     ],
     title: {
      default: "Cancer in close family members",
      "ru-RU": "Онкология у близких родственников"
     }
    }
   ],
   visibleIf: "{gender} = 'man'",
   enableIf: "{gender} = 'man'"
  },
  {
   name: "2.2 Cancer Woman",
   elements: [
    {
     type: "panel",
     name: "panel5",
     elements: [
      {
       type: "checkbox",
       name: "cervicalCancerWoman",
       width: "200px",
       minWidth: "200px",
       maxWidth: "200px",
       startWithNewLine: false,
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Шейки матки",
          default: "Cervix cancer"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "colonCancerWoman",
       width: "200px",
       minWidth: "200px",
       maxWidth: "200px",
       startWithNewLine: false,
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Прямой кишки",
          default: "Colon cancer"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "stomachCancerWoman",
       width: "200px",
       minWidth: "200px",
       maxWidth: "200px",
       startWithNewLine: false,
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Желудка",
          default: "Stomach cancer"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "lungsCancerWoman",
       width: "200px",
       minWidth: "200px",
       maxWidth: "200px",
       startWithNewLine: false,
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Легких",
          default: "Lungs cancer"
         }
        }
       ]
      },
      {
       type: "checkbox",
       name: "melanomaWoman",
       width: "200px",
       minWidth: "200px",
       maxWidth: "200px",
       startWithNewLine: false,
       titleLocation: "hidden",
       choices: [
        {
         value: "true",
         text: {
          "ru-RU": "Меланома",
          default: "Melanoma"
         }
        }
       ]
      }
     ],
     title: {
      "ru-RU": "Онкология у близких родственников",
      default: "Cancer in close relatives"
     }
    }
   ],
   visibleIf: "{gender} = 'woman'",
   enableIf: "{gender} = 'woman'",
   description: {
    default: "Select the diseases that your close relatives had: your mother, aunt, or sister",
    "ru-RU": "Выберите заболевания, которые были у близких родственников: матери, тети или сестры"
   }
  },
  {
   name: "2.1.1 Prostate-cancer",
   elements: [
    {
     type: "radiogroup",
     name: "prostateCancerDetails",
     title: {
      "ru-RU": "Расскажите подробнее про рак простаты у родственников",
      default: "Tell us more about prostate cancer in your relatives"
     },
     choices: [
      {
       value: "morethan1",
       text: {
        "ru-RU": "Рак простаты был у более одного близкого родственника в молодом возрасте",
        default: "More than one close relative had prostate cancer at a young age"
       }
      },
      {
       value: "under65",
       text: {
        "ru-RU": "У отца или брата был рак простаты до 65 лет",
        default: "Father or brother had prostate cancer before he was 65"
       }
      },
      {
       value: "over65",
       text: {
        "ru-RU": "У близкого родственника был рак простаты после 65 лет",
        default: "A close relative had prostate cancer after age 65"
       }
      }
     ]
    }
   ],
   visibleIf: "{prostateCancer} = [true]",
   title: {
    default: "Tell us more about prostate cancer in your relatives",
    "ru-RU": "Расскажите подробнее про рак простаты у родственников"
   }
  },
  {
   name: "2.1.2 StomachCancer",
   elements: [
    {
     type: "radiogroup",
     name: "stomachCancerDetails",
     title: {
      "ru-RU": "Расскажите подробнее про рак желудка у родственников",
      default: "Tell us more about gastric cancer in relatives"
     },
     choices: [
      {
       value: "morethan3",
       text: {
        "ru-RU": "Три и более случаев рака желудка в семье в одной линии",
        default: "Three or more cases of gastric cancer in a family in the same line"
       }
      },
      {
       value: "under40",
       text: {
        default: "One case of gastric cancer in a close relative under the age of 40",
        "ru-RU": "Один случай рака желудка у близкого родственника в возрасте до 40 лет"
       }
      },
      {
       value: "under50",
       text: {
        default: "Two cases of gastric cancer in close relatives under the age of 50",
        "ru-RU": "Два случая рака желудка у близких родственников в возрасте до 50 лет"
       }
      },
      {
       value: "complex",
       text: {
        "ru-RU": "Случай рака желудка и рака молочной железы у близкого родственника с ранним (до 50 лет) выявлением хотя бы одной из этих локализаций, ювенильный полипоз и аденоматозный полипоз у близких родственников.",
        default: "A case of gastric cancer and breast cancer in a close relative with early (before age 50) detection of at least one of these localizations, juvenile polyposis, and adenomatous polyposis in close relatives."
       }
      },
      {
       value: "none",
       text: {
        "ru-RU": "Ничего из перечисленного",
        default: "Nothing of the above"
       }
      }
     ]
    }
   ],
   visibleIf: "{stomachCancerMan} = [true] or {stomachCancerWoman} = [true]",
   title: {
    "ru-RU": "Расскажите подробнее про рак простаты у родственников",
    default: "Tell us more about prostate cancer in your relatives"
   }
  },
  {
   name: "2.3 colonCancer",
   elements: [
    {
     type: "radiogroup",
     name: "colonCancerDetails",
     visibleIf: "{colonCancerMan} = [true] or {colonCancerWoman} = [true]",
     title: {
      "ru-RU": "Нужно больше информации про случаи рака прямой кишки у родственников",
      default: "We need more information about cases of rectal cancer in relatives"
     },
     choices: [
      {
       value: "lessthan60",
       text: {
        "ru-RU": "Рак был у близкого родственника моложе 60 лет",
        default: "A close relative under 60 had cancer"
       }
      },
      {
       value: "manyRelatives",
       text: {
        "ru-RU": "Рак был у нескольких родственников любого возраста",
        default: "Several relatives of all ages have had cancer"
       }
      },
      {
       value: "over60",
       text: {
        "ru-RU": "Рак был у близкого родственника старше 60 лет",
        default: "A close relative over 60 had cancer"
       }
      },
      {
       value: "manyCousins ",
       text: {
        "ru-RU": "Рак был у нескольких двоюродных родственников ",
        default: "Several cousins had cancer"
       }
      }
     ]
    }
   ]
  },
  {
   name: "3. Finish",
   elements: [
    {
     type: "html",
     name: "question2",
     html: {
      "ru-RU": "<h3> Ваш чеклист почти готов! </h3> \n<p>Мы собрали всю нужную информацию, чтобы подготовить для вас индивидуальный план медицинских обследований.</P>\n<p> Стоимость отчета – 99 рублей единоразово. Оплата возможна по карте или через Apple Pay или Google Pay.<p>\n<small>Оплата происходит через защищенное соединение. Вы платите 99 рублей единоразово и сможете вернуться к своему чеклисту в течение года, скачать или распечатать его, при желании. Если что-то пошло не так, пожалуйста напишите на <a href=\"mailto:support@mychecklist.ru\">support@mychecklist.ru</a>, мы обязательно разберемся.</small></p>",
      default: "<h3> Your checklist is almost ready! </h3> \n<p>We've compiled all the information you need to prepare a personalized medical checklist for you.\n<p>The cost of the report is $9.90 per one time payment. You can pay by card or via Apple Pay or Google Pay.<p>\n<small>Payment is made through a secure connection. You pay a one-time fee and can return to your checklist throughout the year, download it or print it out if you want. If something went wrong, please email <a href=\"mailto:support@mychecklist.ru\">support@mychecklist.ru</a>, we'll be sure to figure it out.</small></p>"
     }
    }
   ]
  }
 ],
 showPageTitles: false,
 showCompletedPage: false,
 showQuestionNumbers: "off",
 showProgressBar: "bottom",
 pagePrevText: {
  default: "Back",
  "ru-RU": "Назад"
 },
 pageNextText: {
  default: "Next",
  "ru-RU": "Дальше"
 },
 completeText: {
  default: "Prepare checklist",
  "ru-RU": "Подготовить отчет"
 },
 requiredText: ""
}