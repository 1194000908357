import React from 'react';
import { useTranslation } from 'react-i18next';

const EveryYear = (props) => {

  const { t } = useTranslation();

    return (
       <div class="everyyear">
        <div id="w-node-_433c158a-dc7b-e654-316a-745df673492f-00ae4eac" class="year-number-block">
          <div class="age">{t("Каждый год")}</div>
        </div>
        <div class="year-content">
          <div class="test-block">
            <div class="test-text">{t("💉 Прививка от гриппа")}</div>
            <div class="desc">{t("Ежегодно от осложнений сезонного гриппа умирают до 650 тысяч человек. Прививка от гриппа разрабатывается каждый год и ориентируется на штамм текущего года, поэтому очень эффективна.")}</div>
          </div>
          <div class="test-block">
            <div class="test-text">{t("💉 Прививка от ковида")}</div>
          </div>
          <div class="test-block">
            <div class="test-text">{t("👩  Осмотр терапевта")}</div>
          </div>
          <div class="test-block">
            <div class="test-text">{t("🩸 Общий анализ крови")}</div>
          </div>
          <div class="disclamer">
            <div><strong>{t("Напоминание:")}</strong> {t("не обращайте внимание на список, если чувствуете, что надо обратиться к доктору. Всегда обращайте внимание на внутренние ощущения и не откладывайте визит к доктору")}.</div>
          </div>
        </div>
      </div>
    );
}

export default EveryYear;