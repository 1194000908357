import React, { Component, useEffect } from "react";
import axios from "axios";
import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { motion } from "framer-motion"


import MyCheckListLogo from '../images/mychecklist-logo-2.png';
import MyCheckListLogoEn from '../images/mychecklist-logo-2-en.png';
import ReactPixel from 'react-facebook-pixel';




import warning from "../images/attention-icon.svg"
import printIcon from "../images/print.svg"

import _ from "lodash";

import Year from "../components/Year";
import Card from "../components/card";
import Loader from "../components/loader"
import EveryYear from "../components/everyYear";
import { PurchaseBlock } from "../components/purchaseBlock";

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

var surveyObj;
var testResultObj;
var yearsWithTests = [];
var showLoader = true;


  function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}


const Result = (props) => {

   const forceUpdate = useForceUpdate();

  const [userData, setUserData] = useState([]);
  const [userYears, setUserYears] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [createdAt, setCreatedAt] = useState([]);
  const [testAnchors, setTestAnchors] = useState([]);  
  
  const { t } = useTranslation();

  const history = useHistory();
  const state = props.location.state;
  var pin;
  var arrayOfArrays;

  ReactPixel.init('818300035508177', undefined, {autoConfig: true, debug: false});


    var currency = [9.90, "USD", "en-US", "Payment for creating a personal checklist at mychecklist.ru"]

  if (i18n.language.includes("ru")) {
    currency = [99, "RUB", "ru-RU", "Оплата создания персонального чеклиста на сайте mychecklist.ru"]
  }

  const hasPaid = useRef(true);
 

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }




  if (typeof state == "undefined") {
    const cookies = getCookie("pin");
    if (cookies) {
      pin = cookies;
    } else {
      pin = "nonexistingPin666"
    }
    
  } else { 
    pin = state["pin"]
  }

  arrayOfArrays = [];

  useEffect(() => {
    getSurvey();
    getTestsArray();
    
  }, []);


  const getTestsArray = () => {
    axios.get(t('testAnchorsJson'))
    .then((response) => {
      setTestAnchors(response.data);
    })
  }






  function handlePayment() {
   //  e.preventDefault();

    var widget = new window.cp.CloudPayments({language: currency[2]});

    widget.pay(
      "auth", // или 'charge'
      {
        //options
        publicId: "pk_6555be2348a3155394d4ef66d2355", //id из личного кабинета
        description: currency[3], //назначение
        amount: currency[0], //сумма
        currency: currency[1], //валюта
      // accountId: "user@example.com", //идентификатор плательщика (необязательно)
      //  invoiceId: "1234567", //номер заказа  (необязательно)
        skin: "mini", //дизайн виджета (необязательно)
        
        data: {
          myProp: "myProp value",
        },
      },
      {
        onSuccess: function (options) {
          // success
           axios
              .post(`https://checklistbackend-serovg.b4a.run/users/${pin}/paymentCompleted`)
              .then((res) => {console.log(res); getSurvey(); })
              .catch((error) =>
                console.log("Something went wrong while proceeding a payment survey results")
              );
          hasPaid.current = true;    
        },

        onFail: function (reason, options) {
          
        
        },
        onComplete: function (paymentResult, options) {
          if(paymentResult.success == true) {
             /*global ym*/
             ym(83110318,'reachGoal','paymentCompleted')
             ReactPixel.track('paymentCompleted', {currency: "USD", value: 8.00});
            getSurvey();
           // forceUpdate();
          }
          console.log(paymentResult)
        },
      }
    );
  }


  const getSurvey = () => {
    setShowLoader(true)
    axios
      .get(`https://checklistbackend-serovg.b4a.run/users/${pin}`)
      .then((res) => {
        setUserData(testResultObj);

        if (Object.keys(res.data).length === 0) {
          console.log("no data found");
          history.push({ pathname: '/' })
        } else {
          surveyObj = JSON.parse(res.data[0].survey);
          testResultObj = res.data[0].tests;
          hasPaid.current = res.data[0].hasPaid
          console.log("This is from server" + res.data[0].hasPaid);
          testResultObj.forEach((element) => {
          arrayOfArrays.push(element.years);
          });
          let yearsWithTests = _.union.apply(_, arrayOfArrays);
          yearsWithTests.sort();
          setUserData(testResultObj);
          setUserYears(yearsWithTests);
          setCreatedAt(res.data[0].createdAt);
          setTimeout(() => {setShowLoader(false)}, 400 );

          document.cookie = `pin=${pin}`;

        }
      })
      .catch((err) => console.log(err));
      
  };

function handleTestPayment(e) {
    e.preventDefault();

      axios
              .post(`https://checklistbackend-serovg.b4a.run/users/${pin}/paymentCompleted`)
              .then((res) => {console.log(res); getSurvey()})
              .catch((error) =>
                console.log("Something went wrong while proceeding a payment survey results")
              );
  }
const print = () => {
    window.print();
  }

  var logo = MyCheckListLogo;


  if (i18n.language.includes("en")) {
      logo = MyCheckListLogoEn;
  }

  return (<div className="body">
    {showLoader ? <Loader /> : null }
    
    <div className="columns">
      <div className="sidebar">
      <div className="fixeddiv"><Link to={{pathname: '/', state: { prevPath: window.location.pathname }}}><img  src={logo} width="165px" loading="lazy" alt="" class="image-11"/></Link>
        
          <div className="pin-container">
            <div className="pin">{pin}</div>
            <div className="body-text"><p>{t("Не беспокойтесь, эта страница не пропадет. Сохраните код, чтобы вернуться к списку в течение года")}</p><p>{t("Добавьте эту страницу в закладки, чтобы вернуться к чеклисту позднее")}</p></div>

          </div>
          <Link to="/test" style={{ textDecoration: 'none' }}>
          <div className="cta-button-result"><span className="cta-button-result-text">{t("Пройти тест еще раз с другими вводными")}</span></div>
          </Link>
          <div class="disclaimer"><img src={warning} loading="lazy" width="27" alt=""/>
          <div class="text-block-2">{t("Мы рекомендуем исследования, которые нужно проходить здоровым людям.")}<br/>‍<strong>{t("При появлении любых симптомов – сразу идите к врачу.")}</strong></div>
        </div>
        <div onClick={print} className="print-button"><img src={printIcon} loading="lazy" width="21" alt="" class="image" />
          <div>{t("Распечатать")}</div>
        </div>
       
       
      </div>
    </div>
      <div className="content">
        <h1 className="heading">{t("Вот какие исследования")}<br/>{t("мы рекомендуем вам провести")}</h1>
          <EveryYear />
          {userYears.map((year) => (
            <Year year={year} testResult={userData} age={surveyObj.age} createdAt={createdAt} testAnchors = {testAnchors}/>
          ))}
        <p>{t("Есть вопрос – ")}<a href="mailto:support@mychecklist.ru">support@mychecklist.ru</a></p>
      </div>
     
    </div>
    <PurchaseBlock hasPaid={hasPaid.current} handlePayment={handlePayment} />
    </div>
  );
};

export default Result;
