import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../images/checklist-logo-animation.json'
import { useTranslation } from 'react-i18next';

const Loader = (props) => {

  const { t } = useTranslation();

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    return (
        <div class="loading-container">
        <div class="survey-container">
            <Lottie options={defaultOptions}
              height={100}
              width={100}
              />
          <p>{t("Готовим отчет")}</p>
        </div>
      </div>
    );
}

export default Loader;