import React, {useState} from "react";
import closeSign from '../images/close-sign.svg'

export const Popup = ({children}) => {
const popupOverlay = {
  
    zIndex: '9999999',
    height: '100%',
    display: "grid",
  position: "fixed",
  left: "0%",
  top: "0%",
  right: "0%",
  bottom: "0%",
  WebkitBoxPack: "center",
  WebkitJustifyContent: "center",
  msFlexPack: "center",
  justifyContent: "center",
  WebkitBoxAlign: "center",
  WebkitAlignItems: "center",
  msFlexAlign: "center",
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.69)",
  color: "rgba(0, 0, 0, 0.83)"
}

const popupWindow = {
    position: "relative",
    display: "block",
    maxWidth: "700px",
    margin: "auto",
    padding: "30px 50px 47px",
    WebkitBoxOrient: "vertical",
    WebkitBoxDirection: "normal",
    WebkitFlexDirection: "column",
    msFlexDirection: "column",
    flexDirection: "column",
    WebkitBoxPack: "center",
    WebkitJustifyContent: "center",
    msFlexPack: "center",
    justifyContent: "center",
    WebkitBoxAlign: "start",
    WebkitAlignItems: "flex-start",
    msFlexAlign: "start",
    alignItems: "flex-start",
    gridAutoColumns: "1fr",
    msGridColumns: "0.5fr 1.5fr 0.25fr",
    gridTemplateColumns: "0.5fr 1.5fr 0.25fr",
    msGridRows: "auto",
    gridTemplateRows: "auto",
    borderRadius: "12px",
    backgroundColor: "#fff",
    boxShadow: "0 19px 50px -17px #000",
  
}

const popupContainer = {
    overflow: 'auto',
    maxHeight: "600px",
}



    
  return <div style={popupOverlay}>
              
            <div style={popupWindow}>

                {children[1]}

                 <div style={popupContainer}>
                        {children[0]}
                 </div>     

            </div>
           
           
        </div>

    }

    export const CloseButton = () => {

        const closeButton = {
            position: "absolute",
            left: "auto",
            width: "40px",
            height: "40px",
            top: "2%",
            right: "2%",
            bottom: "auto",
            backgroundImage: `url(${closeSign})`,
           
          }

          return  <div style={closeButton}></div>
    }
