import React, { Component, useEffect } from "react";

import { useState } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import { json } from "./questions.jsx";
import axios from "axios";
import * as securePin from "secure-pin";
import { useHistory } from "react-router-dom";
import Loader from "../loader";

import ReactPixel from 'react-facebook-pixel';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

//import { response } from "express";

const Welcome = () => {



  const [pin, setCount] = useState(0);
  const [showPin, setShowPin] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [surveyJson, setSurveyJson] = useState(json);
  const history = useHistory();

  ReactPixel.init('818300035508177', undefined, {autoConfig: true, debug: false});


  var currency = [9, "USD", "en-US", "Payment for creating a personal checklist at mychecklist.ru"]

  if (i18n.language.includes("ru")) {
    currency = [99, "RUB", "ru-RU", "Оплата создания персонального чеклиста на сайте mychecklist.ru"]
  }

  useEffect(() => {
              
        axios.get('https://api.surveyjs.io/public/Survey/getSurvey?surveyId=7f398a37-0303-475c-bb70-cc9421dd13b8')
        .then((response => {
          console.log(response.data);
          setSurveyJson(response.data);
    }))

  },[])

  useEffect(() => {

    const script = document.createElement("script");

    script.src = "https://widget.cloudpayments.ru/bundles/cloudpayments";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  

  function checkIfUserAlreadyExists(pin) {

    var isUserExists = false

    axios.get("https://checklistbackend-serovg.b4a.run/users/" + pin).then((response) => {
      console.log("Responce data is " + typeof response.data[0]);
      if (typeof response.data[0] !== "undefined") {
        isUserExists = true;
      }
    });

    return isUserExists
  }

  function generateUniquePin() {
    var uniquePin = null;

       

    var isUserExists = true

    var count = 2
    do {
      uniquePin = securePin.generatePinSync(4);
      isUserExists = checkIfUserAlreadyExists(uniquePin)
      count -= 1

    } while (isUserExists && count !== 0)
    return uniquePin;
  }


  function sendDataToServer(survey) {
    var pin = generateUniquePin();

     /*global ym*/
    ym(83110318,'reachGoal','checklistReady')
    ReactPixel.track('checklistReady', {currency: "USD", value: 0.00});

    setCount(pin);
    setShowLoader(true);
    if(survey.valuesHash.metric == "metric") 
      {survey.valuesHash["bmi"] =
      survey.valuesHash.weight /
      ((survey.valuesHash.height * survey.valuesHash.height) / 10000);}

    if(survey.valuesHash.metric == "imperial") 
      {survey.valuesHash["bmi"] =
      survey.valuesHash.weightPounds /
      (((survey.valuesHash.heightFeet * 12) + survey.valuesHash.heightInches)**2) * 703;}  


    var resultAsString = JSON.stringify(survey.data);
    console.log(resultAsString);

    axios
      .post("https://checklistbackend-serovg.b4a.run/users/add", {
        username: `${pin}`,
        survey: `${resultAsString}`,
        hasPaid: false,
      })
      .then((response) => {
        setShowPin(true);
        history.push({ pathname: "/result", state: { pin } });
          
      })
      .catch((error) =>
        console.log("Something went wrong while posting survey results")
      );
  }



  return (
    
    <div>
      {console.log(surveyJson)}
      {showLoader ? <Loader /> : null}
      <Survey.Survey
        json={surveyJson}
        locale = {i18n.language}
        onComplete={sendDataToServer}
        showCompletedPage={false}
      />
     
    </div>
  );
};

export default Welcome;
