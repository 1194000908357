import React, { Component, useEffect } from "react";
import Card from "./card";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Year = (props) => {

  const { t } = useTranslation();
 

  const countAge = (year, age, createdAt) => {

    if(typeof(age) === "undefined") {
      age = 18;
    }

    
    var currentAgeString = ""
    let currentAge = 0
    //const currentYear = new Date().getFullYear();
    const currentYear = new Date(createdAt).getFullYear();


   
    currentAge = year - currentYear + Number(age)
   
     currentAgeString = currentAge + " years"
    
    if (i18n.language.includes("ru") || i18n.language == "ru-RU")
    {switch(currentAge % 10){
      case (0): currentAgeString = currentAge + " лет"; break;
      case (1): currentAgeString = currentAge + " год"; break;
      case (2): case (3): case (4): 
      currentAgeString = currentAge + " года"; break;
      case (5): case (6): case (7): case (8): case (9):
      currentAgeString = currentAge + " лет"; break;
      default: currentAgeString = currentAge;
    }}
    

    return currentAgeString;
  }

  let cardItems = [];


  props.testResult.forEach((currentItem) => {
    if (currentItem.years.includes(props.year)) {
      cardItems.push(<Card test={currentItem.testAnchor} testAnchors={props.testAnchors} />);
    }
  });


  return (
    <div className="year">
      <div className="year-number-block">
        <div className="age">{countAge(props.year, props.age, props.createdAt)}</div>
        <div className="year-number-text">{props.year}</div>  
      </div>
      
      <div className="year-content">{cardItems}</div>
    
    </div>
  );
};  

export default Year;
