import React, { Component } from "react";

const NotFoundPage = () => {
  return (
        <p>
         404
        </p>
  );
}

export default NotFoundPage;