import React from 'react';
import { useTranslation } from 'react-i18next';


import hands from '../images/payment/hands.png'
import lock from '../images/payment/lock.svg'
import mir from '../images/payment/mir.svg'
import mastercard from '../images/payment/mastercard.svg'
import gpay from '../images/payment/gpay.svg'
import visa from '../images/payment/gpay.svg'
import applePay from '../images/payment/applepay.svg'



export const PurchaseBlock = (props) => {

const click = (e) => {
    e.preventDefault();
    props.handlePayment();
}

 const { t } = useTranslation();

 if (props.hasPaid === false) {
     return  (
    <div class="purchaisecontainer">
    <div class="purchaseblock"><img src={hands} loading="lazy" width="274" id="w-node-_33bbf7fb-d867-4526-794c-f7d7f5592f94-00ae4eac" alt="" class="image-14"/>
      <div id="w-node-_3d647e7d-4e25-db88-466c-dd0a56fd3993-00ae4eac" class="demotext">
        <h4 class="demoheading">{t("Это бесплатная демо-версия вашего чеклиста")}</h4>
        <div class="text-block-10">{t("Только некоторые тесты показаны. Персональные тесты скрыты.")}</div>
      </div>
      <div id="w-node-d3cce4b6-d2f0-ff8a-60e3-196d6d8671a0-00ae4eac" class="paymentbuttoncontainer">
        <div id="w-node-_108dc98f-206b-f0c7-d6a5-9f91bbf86491-00ae4eac" onClick={click} class="paymentbutton">
          <div id="w-node-f78168f0-cf66-aacc-2dbd-84fa5b8ddbbd-00ae4eac" class="paymentbuttontext">{t("Открыть все тесты одноразовым платежом")}</div>
          <div id="w-node-_796325f7-184b-58dd-ce27-a729aaa2d8c3-00ae4eac" class="div-block-21">
            <div class="text-block-8">{t("₽")}</div>
            <div class="pricetext">{t("99")}</div>
          </div>
        </div>
        <div class="paymentsystems">
          <div class="sll"><img src={lock} loading="lazy" alt=""/>
            <div class="text-block-9">{t("Безопасный платеж")}<br/>{t("with SLL encryption")}</div>
          </div>
          <div class="div-block-22"><img src={visa} loading="lazy" id="w-node-_8f0155fe-6632-a8a1-710c-785a97af8379-00ae4eac" alt="" class="visa"/><img src={mastercard} loading="lazy" id="w-node-d9358269-d34e-aa03-24c8-c16721a8f353-00ae4eac" alt="" class="mastercard"/><img src={applePay} loading="lazy" alt="" class="applepay"/><img src={mir} loading="lazy" id="w-node-cdb451d9-0dc5-dae8-a6c3-95157637825a-00ae4eac" alt="" class="mir"/><img src={gpay} loading="lazy" id="w-node-d1458575-51ad-82bd-211c-dd38bfc6c5e9-00ae4eac" alt="" class="image-15"/></div>
        </div>
      </div>
    </div>
  </div>)
 }
 else { return null }

    
}